@font-face {
  font-family: "CodeNewRoman";
  src: url("./fonts/CodeNewRomanNerdFont-Regular.otf");
}

@font-face {
  font-family: "CodeNewRomanMono";
  src: url("./fonts/CodeNewRomanNerdFontMono-Regular.otf");
}

body {
  margin: 0;
  padding: 0;
  font-family: "CodeNewRoman";
}

p > a[target="_blank"]::after {
  content: "⤴︎";
  color: #f1592a;
}
